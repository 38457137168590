@import "util/Color.scss";

.PVModal {
  position: absolute;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $Gblack-Opacity;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
