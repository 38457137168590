@import "util/Color.scss";

.menuIcon {
  width: 2rem;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 98;
}

.menu {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 25rem;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $Gwhite;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 0 20px 20px 0;
  padding: 2rem;
  transition: all 0.3s;

  &-logo {
    width: 50%;
  }

  &-close {
    width: 1.4rem;
    position: absolute;
    top: 2.2rem;
    right: 1.8rem;
  }

  &-list {
    width: 100%;
    height: calc(var(--vh, 1vh) * 80);
    display: flex;
    flex-direction: column;
    align-items: center;

    &-card {
      position: relative;
      width: 90%;
      height: 5rem;
      background-color: black;
      border-radius: 12px;
      color: $Fwhite;
      margin-top: 1rem;
      padding: 0 1.4rem;
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s;

      &:hover .cover {
        left: 0;
      }

      div {
        z-index: 20;
      }

      &-text {
        display: flex;
        align-items: center;

        img {
          margin-right: 0.5rem;
          height: 14px;
        }
      }
    }
  }

  &-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: $Fgray;
    font-size: 0.8rem;

    a {
      color: $Fgray;
    }
  }
}

.mainmenu {
  background-position-y: -3rem;
  background-image: url("../../assets//img/Illust3.jpg");
}

.prologmenu {
  background-image: url("../../assets/img/BG1-2.jpg");
}

.worldmenu {
  background-image: url("../../assets/img/Banner0.png");
}

.merhenmenu {
  background-position-y: -1rem;
  background-image: url("../../assets/img/Illust6.png");
}

.resmenu {
  background-position-y: -2rem;
  background-image: url("../../assets/img/Illust2.jpg");
}

.cover {
  position: absolute;
  width: 100%;
  height: 5rem;
  background-color: $Gskyblue;
  top: -1rem;
  left: -100%;
}

.hide {
  left: -25rem;
}

.show {
  left: 0;
}

@media screen and (max-width: 400px) {
  .cover {
    display: none;
  }

  .menu {
    width: 20rem;
    padding: 2rem;

    &-logo {
      width: 50%;
    }

    &-close {
      width: 1.4rem;
      position: absolute;
      top: 2.2rem;
      right: 1.8rem;
    }

    &-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;

      &-card {
        width: 90%;
        height: 4rem;
        margin-top: 1rem;
        padding: 0 1.4rem;
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        transition: all 0.3s;

        &:hover .cover {
          left: 0;
        }

        div {
          z-index: 20;
        }
      }
    }

    &-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 24vh;
      color: $Fgray;
      font-size: 0.8rem;

      a {
        color: $Fgray;
      }
    }
  }
}
