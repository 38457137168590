@import "util/Color.scss";

.areaboxSc {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 1%;
}

.scrollArea {
  margin-right: 10rem;
  z-index: 98;
  animation: scroll 2s infinite alternate;
  -moz-animation: scroll 2s infinite alternate;
  -webkit-animation: scroll 2s infinite alternate;
  -o-animation: scroll 2s infinite alternate;
}

.scrollBox {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: fixed;

  &-area {
    width: 100%;
    height: 100%;
    touch-action: pan-y;
    transition: all 1s;
  }
}

@keyframes scroll {
  0% {
    margin-bottom: 0;
    opacity: 1;
  }

  100% {
    margin-bottom: 20px;
    opacity: 0.5;
  }
}

@media screen and (max-width: 700px) {
  .areaboxSc {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 1%;
  }

  .scrollArea {
    margin-right: 0;
    width: 2.5rem;
  }
}

.charactor {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/img/Illust1.jpg");
  transition: all 0.3s;
  position: relative;

  &-cover {
    width: 100%;
    height: 100%;
    background-color: $Gblack-Mobile;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
}
