@import "util/Color.scss";
@import "util/Animation.scss";

.InfoArea {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;

  &-monitor {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    flex-direction: column;

    &-lang {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 1rem 1rem 0.8rem 1rem;
      color: $Fgray;

      &-texton {
        box-sizing: border-box;
        margin-left: 1rem;
        opacity: 0;
        transition: all 0.3s;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0);
        display: flex;
        justify-content: center;
        align-items: center;

        & a {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &-textoff {
        box-sizing: border-box;
        margin-left: 1rem;
        opacity: 1;
        transition: all 0.3s;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0);
        display: flex;
        justify-content: center;
        align-items: center;

        & a {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &:hover &-texton {
        opacity: 0.15;
        border-bottom: 1px solid #8967ff;
      }

      &:hover &-textoff {
        border-bottom: 1px solid #8967ff;
      }
    }
  }
}

.ReservationText {
  margin-top: 1rem;
  max-width: 50%;
}

.ReservationTextKR {
  margin-top: 1rem;
  max-width: 37%;
}

.InfoArea-monitor-sns {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: all 0.3s;

  &-cover {
    margin-top: 1rem;
    width: 3.8rem;
    height: 2.2rem;
    color: $Fwhite;
    display: flex;
    align-items: center;
    border-radius: 10px 0 0 10px;
    font-size: 0.8rem;
    transition: all 0.3s;

    &-img {
      width: 1.8rem;
      margin: 0 1rem;
      opacity: 0.6;
    }

    &:hover {
      width: 12rem;
      background-color: $Gskyblue;
    }

    &:hover .font {
      left: 70px;
    }
  }
}

.InfoTextArea {
  width: 100%;
  height: 100%;
  background-color: $Gblack-Opacity2;
  display: flex;
  justify-content: center;
}

.Info {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.InfoLogo {
  width: 19rem;
  object-fit: contain;
  margin-top: 1rem;
  margin-bottom: 1.8rem;
}

.InfoLogoKR {
  width: 16rem;
  object-fit: contain;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  left: 1.2rem;
}

.InfoDownload {
  margin-bottom: 10rem;
  width: 100%;
  max-width: 40rem;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  &-main {
    height: 98px;
  }

  &-pre {
    height: 90px;
  }

  img {
    object-fit: contain;
  }
}

.InfoAreaBg {
  background-image: url(../../assets/img/bg1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.logocafe {
  width: 8rem;
  margin-right: 1rem;
  margin-top: 1rem;
}

.playIcon {
  width: 7rem;
  height: 7rem;
  position: relative;
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.9;
  object-fit: contain;

  &-area {
    width: 6.5rem;
    height: 6.5rem;
    position: relative;
    animation: spiner 6s linear infinite;
  }

  &-start {
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: calc(50% - 1.1rem);
    top: calc(50% - 1.5rem);
  }
}

@media screen and (max-width: 800px) {
  .InfoArea {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background-position: center;
    background-position-x: 70%;
    background-size: cover;
    background-image: url("../../assets/img/m_bg.jpg");

    &-monitor {
      &-lang {
        display: none;
      }

      &-sns {
        display: none;
      }
    }
  }
  .playIcon {
    display: none;
  }

  .Info {
    justify-content: center;
  }

  .InfoPlayMobile {
    width: 4rem;
    height: 4rem;
    position: relative;
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-area {
      width: 4rem;
      height: 4rem;
      position: relative;
      animation: spiner 6s linear infinite;
    }

    &-start {
      width: 1.6rem;
      height: 1.6rem;
      position: absolute;
      left: calc(50% - 0.6rem);
      top: calc(50% - 0.8rem);
    }
  }

  .preLineTextArea {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-text {
      width: 90%;
    }

    &-line {
      width: 100%;
      position: relative;
      top: -0.4rem;
      z-index: 99;
      height: 7px;
      object-fit: contain;
    }
  }

  .InfoDownload {
    margin-bottom: 2rem;
    width: 28rem;
    display: flex;
    justify-content: space-between;

    &-main {
      height: 75px;
    }

    &-pre {
      height: 63px;
    }
  }
}

@media screen and (max-width: 500px) {
  .InfoArea {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background-position: center;
    background-position-x: 70%;
    background-size: cover;

    &-monitor {
      &-lang {
        display: none;
      }

      &-sns {
        display: none;
      }
    }
  }

  .preLineTextArea {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-textKR {
      width: 75%;
    }

    &-text {
      width: 90%;
    }

    &-line {
      width: 100%;
      position: relative;
      top: -0.4rem;
      z-index: 99;
      height: 7px;
      object-fit: contain;
    }
  }

  .logocafe {
    width: 6rem;
  }

  .InfoDownload {
    width: 22rem;
    display: flex;
    justify-content: space-between;

    &-main {
      height: 58px;
    }

    &-pre {
      height: 48px;
    }
  }
}
