@import "util/Color.scss";

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeinUP {
  from {
    opacity: 0;
    background-position-y: 8rem;
  }
  to {
    opacity: 1;
    background-position-y: 10rem;
  }
}
@-moz-keyframes fadeinUP {
  from {
    opacity: 0;
    background-position-y: 12rem;
  }
  to {
    opacity: 1;
    background-position-y: 10rem;
  }
}
@-webkit-keyframes fadeinUP {
  from {
    opacity: 0;
    background-position-y: 12rem;
  }
  to {
    opacity: 1;
    background-position-y: 10rem;
  }
}
@-o-keyframes fadeinUP {
  from {
    opacity: 0;
    background-position-y: 12rem;
  }
  to {
    opacity: 1;
    background-position-y: 10rem;
  }
}

@keyframes fadeinLeft {
  from {
    opacity: 0;
    left: -1rem;
  }
  to {
    opacity: 1;
    left: 0;
  }
}
@-moz-keyframes fadeinLeft {
  from {
    opacity: 0;
    left: -1rem;
  }
  to {
    opacity: 1;
    left: 0;
  }
}
@-webkit-keyframes fadeinLeft {
  from {
    opacity: 0;
    left: -1rem;
  }
  to {
    opacity: 1;
    left: 0;
  }
}
@-o-keyframes fadeinLeft {
  from {
    opacity: 0;
    left: -1rem;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeinLeft-1400 {
  from {
    opacity: 0;
    left: 3%;
  }
  to {
    opacity: 1;
    left: 5%;
  }
}
@-moz-keyframes fadeinLeft-1400 {
  from {
    opacity: 0;
    left: 3%;
  }
  to {
    opacity: 1;
    left: 5%;
  }
}
@-webkit-keyframes fadeinLeft-1400 {
  from {
    opacity: 0;
    left: 3%;
  }
  to {
    opacity: 1;
    left: 5%;
  }
}
@-o-keyframes fadeinLeft-1400 {
  from {
    opacity: 0;
    left: 3%;
  }
  to {
    opacity: 1;
    left: 5%;
  }
}

@keyframes fadeinRight {
  from {
    opacity: 0;
    right: 0;
  }
  to {
    opacity: 1;
    right: 4rem;
  }
}
@-moz-keyframes fadeinRight {
  from {
    opacity: 0;
    right: 0;
  }
  to {
    opacity: 1;
    right: 4rem;
  }
}
@-webkit-keyframes fadeinRight {
  from {
    opacity: 0;
    right: 0;
  }
  to {
    opacity: 1;
    right: 4rem;
  }
}
@-o-keyframes fadeinRight {
  from {
    opacity: 0;
    right: 0;
  }
  to {
    opacity: 1;
    right: 4rem;
  }
}

@keyframes fadeinRight-1400 {
  from {
    opacity: 0;
    right: 8%;
  }
  to {
    opacity: 1;
    right: 10%;
  }
}
@-moz-keyframes fadeinRight-1400 {
  from {
    opacity: 0;
    right: 8%;
  }
  to {
    opacity: 1;
    right: 10%;
  }
}
@-webkit-keyframes fadeinRight-1400 {
  from {
    opacity: 0;
    right: 8%;
  }
  to {
    opacity: 1;
    right: 10%;
  }
}
@-o-keyframes fadeinRight-1400 {
  from {
    opacity: 0;
    right: 8%;
  }
  to {
    opacity: 1;
    right: 10%;
  }
}

@keyframes fadeinRight-1000 {
  from {
    opacity: 0;
    right: 18%;
  }
  to {
    opacity: 1;
    right: 20%;
  }
}
@-moz-keyframes fadeinRight-1000 {
  from {
    opacity: 0;
    right: 18%;
  }
  to {
    opacity: 1;
    right: 20%;
  }
}
@-webkit-keyframes fadeinRight-1000 {
  from {
    opacity: 0;
    right: 18%;
  }
  to {
    opacity: 1;
    right: 20%;
  }
}
@-o-keyframes fadeinRight-1000 {
  from {
    opacity: 0;
    right: 18%;
  }
  to {
    opacity: 1;
    right: 20%;
  }
}

@keyframes fadeinReady {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeinReady {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeinReady {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeinReady {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeUP {
  from {
    margin-top: 2rem;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}
@-moz-keyframes fadeUP {
  from {
    margin-top: 2rem;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}
@-webkit-keyframes fadeUP {
  from {
    margin-top: 2rem;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}
@-o-keyframes fadeUP {
  from {
    margin-top: 2rem;
    opacity: 0;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes fadeinDown {
  from {
    margin-bottom: 2rem;
    opacity: 0;
  }
  to {
    margin-bottom: 0;
    opacity: 1;
  }
}
@-moz-keyframes fadeinDown {
  from {
    margin-bottom: 2rem;
    opacity: 0;
  }
  to {
    margin-bottom: 0;
    opacity: 1;
  }
}
@-webkit-keyframes fadeinDown {
  from {
    margin-bottom: 2rem;
    opacity: 0;
  }
  to {
    margin-bottom: 0;
    opacity: 1;
  }
}
@-o-keyframes fadeinDown {
  from {
    margin-bottom: 2rem;
    opacity: 0;
  }
  to {
    margin-bottom: 0;
    opacity: 1;
  }
}

@keyframes Loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@-moz-keyframes Loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes Loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
@-o-keyframes Loading {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes spiner {
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes spiner {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spiner {
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes spiner {
  100% {
    transform: rotate(360deg);
  }
}
