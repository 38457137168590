//font
$Fblack: #000000;
$Fwhite: #ffffff;
$Fwhite-low: #dfdfdf;
$Fgray: #adadad;

//back
$Gblack: #000000;
$Gblack-low: #101010;
$Gblack-Mobile: rgba(0, 0, 0, 0.8);
$Gblack-Opacity: rgba(0, 0, 0, 0.6);
$Gblack-Opacity2: rgba(0, 0, 0, 0.4);
$Gblack-Opacity3: rgba(0, 0, 0, 0.2);
$Gwhite: #ffffff;
$Gskyblue: #737898;
$Ggray: #adadad;
$GYellow: #fedd2e;

//border
$Bblack: #000000;
$Bwhite: #ffffff;
$BGray: #a7a7a7;
