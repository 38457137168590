@import "util/Color.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

html {
  touch-action: pan-y;
  overscroll-behavior: contain;
  scroll-behavior: smooth;
}

body {
  background-color: $Gblack;
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-family: "Wemakeprice-SemiBold";
  overscroll-behavior: contain;
  touch-action: pan-y;
  width: 100vw;
  position: fixed;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

html {
  width: 100%;
}

.transition-group {
  position: relative;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.hidden {
  overflow: hidden;
}
