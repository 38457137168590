@import "util/Color.scss";

.prologArea {
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  transition: all 0.3s;
}

.prolog {
  width: 100%;
  height: 100%;
  background-color: $Gwhite;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  transition: all 0.3s;
  overflow: hidden;

  &-area {
    width: 33.33%;
    height: 100%;
    transition: all 1s;
    display: flex;
    align-items: center;
  }
}

.chapter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.3s;

  &-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $Gblack-Opacity2;
    transition: all 0.3s;

    &-icon {
      top: -6rem;
      width: 20rem;
      position: relative;
      transition: all 1s;
    }

    &-icon1 {
      top: 0px;
    }

    &-info {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      animation: fadein 1s;
      -moz-animation: fadein 1s;
      -webkit-animation: fadein 1s;
      -o-animation: fadein 1s;
      margin-top: 300px;
    }
  }
}

.hoverPolog {
  &:hover {
    background-color: $Gblack-Opacity3;
  }
}

.left {
  width: 2rem;
  position: absolute;
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  top: calc(50% - 1rem);
  left: 5%;
  z-index: 98;
}

.right {
  width: 2rem;
  position: absolute;
  top: calc(50% - 1rem);
  right: 5%;
  z-index: 98;
}

.chapter1Img {
  background-image: url("../../assets/img/BG1-2.jpg");
}

.chapter1Img2 {
  background-image: url("../../assets/img/BG1-1.jpg");
}

.chapter1Text {
  background-image: url("../../assets/text/KR/Prologue_Aurora1.png");
  max-height: 137px;
  bottom: 30vh;
}

.chapter1Text2 {
  background-image: url("../../assets/text/KR/Prologue_Aurora2.png");
  max-height: 137px;
  bottom: 30vh;
}

.chapter2Img {
  background-image: url("../../assets/img/BG2-2.jpg");
}

.chapter2Img2 {
  background-image: url("../../assets/img/BG2-1.jpg");
}

.chapter2Text {
  background-image: url("../../assets/text/KR/Prologue_Haven1.png");
  max-height: 109px;
  bottom: 33vh;
}

.chapter2Text2 {
  background-image: url("../../assets/text/KR/Prologue_Haven2.png");
  max-height: 81px;
  bottom: 36vh;
}

.chapter3Img {
  background-image: url("../../assets/img/BG3-2.jpg");
}

.chapter3Img2 {
  background-image: url("../../assets/img/BG3-1.jpg");
}

.chapter3Text {
  background-image: url("../../assets/text/KR/Prologue_Kyo1.png");
  max-height: 108px;
  bottom: 33vh;
}

.chapter3Text2 {
  background-image: url("../../assets/text/KR/Prologue_Kyo2.png");
  max-height: 137px;
  bottom: 30vh;
}

.chapter1TextUS {
  background-image: url("../../assets/text/US/Prologue_Aurora1US.png");
  max-height: 137px;
  bottom: 30vh;
}

.chapter1TextUS2 {
  background-image: url("../../assets/text/US/Prologue_Aurora2US.png");
  max-height: 137px;
  bottom: 30vh;
}

.chapter2TextUS {
  background-image: url("../../assets/text/US/Prologue_Haven1US.png");
  max-height: 109px;
  bottom: 32vh;
}

.chapter2TextUS2 {
  background-image: url("../../assets/text/US/Prologue_Haven2US.png");
  max-height: 109px;
  bottom: 32vh;
}

.chapter3TextUS {
  background-image: url("../../assets/text/US/Prologue_Kyo1US.png");
  max-height: 111px;
  bottom: 33vh;
}

.chapter3TextUS2 {
  background-image: url("../../assets/text/US/Prologue_Kyo2US.png");
  max-height: 139px;
  bottom: 30vh;
}

.unnone {
  width: 100%;
}

.none {
  display: none;
}

@media screen and (min-height: 1400px) {
}

@media screen and (max-width: 1400px) {
  .chapter1Text {
    max-height: 137px;
    bottom: 22%;
  }

  .chapter1Text2 {
    max-height: 137px;
    bottom: 22%;
  }

  .chapter2Text {
    max-height: 109px;
    bottom: 27%;
  }

  .chapter2Text2 {
    max-height: 81px;
    bottom: 31%;
  }

  .chapter3Text {
    max-height: 108px;
    bottom: 28%;
  }

  .chapter3Text2 {
    max-height: 137px;
    bottom: 23%;
  }
}

@media screen and (max-width: 1000px) {
  .prologArea {
    min-height: 400px;
  }

  .chapter {
    &-area {
      &-icon {
        width: 40%;
        position: static;
        transition: all 1s;
      }

      &-info {
        position: static;
        margin-top: 1rem;
      }
    }
  }

  .chapter1TextM {
    background-image: url("../../assets/text/KRmobile/Prologue_Aurora1.svg");
    max-height: 163px;
    margin-top: 2rem;
    margin-bottom: 2.8rem;
  }

  .chapter1Text2M {
    background-image: url("../../assets/text/KRmobile/Prologue_Aurora2.svg");
    max-height: 193px;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .chapter2TextM {
    background-image: url("../../assets/text/KRmobile/Prologue_Haven1.svg");
    max-height: 139px;
    margin-bottom: 5rem;
  }

  .chapter2Text2M {
    background-image: url("../../assets/text/KRmobile/Prologue_Haven2.svg");
    max-height: 139px;
    margin-bottom: 5rem;
  }

  .chapter3TextM {
    background-image: url("../../assets/text/KRmobile/Prologue_Kyo1.svg");
    max-height: 154px;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }

  .chapter3Text2M {
    background-image: url("../../assets/text/KRmobile/Prologue_Kyo2.svg");
    max-height: 154px;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }

  .chapter1TextUSM {
    background-image: url("../../assets/text/USmobile/Prologue_Aurora1US.svg");
    max-height: 266px;
  }

  .chapter1TextUS2M {
    background-image: url("../../assets/text/USmobile/Prologue_Aurora2US.svg");
    max-height: 234px;
    margin-bottom: 2rem;
  }

  .chapter2TextUSM {
    background-image: url("../../assets/text/USmobile/Prologue_Haven1US.svg");
    max-height: 204px;
    margin-bottom: 4rem;
  }

  .chapter2TextUS2M {
    background-image: url("../../assets/text/USmobile/Prologue_Haven2US.svg");
    max-height: 204px;
    margin-bottom: 4rem;
  }

  .chapter3TextUSM {
    background-image: url("../../assets/text/USmobile/Prologue_Kyo1US.svg");
    max-height: 200px;
    margin-bottom: 5.5rem;
  }

  .chapter3TextUS2M {
    background-image: url("../../assets/text/USmobile/Prologue_Kyo2US.svg");
    max-height: 285px;
  }
}

@media screen and (max-width: 700px) {
  .chapter {
    &-area {
      &-icon {
        width: 40%;
        animation: fadeinDown 1s;
        -moz-animation: fadeinDown 1s;
        -webkit-animation: fadeinDown 1s;
        -o-animation: fadeinDown 1s;
      }
    }
  }

  .chapter1TextM {
    max-height: 123px;
    margin-bottom: 3rem;
  }

  .chapter1Text2M {
    max-height: 149px;
  }

  .chapter2TextM {
    max-height: 99px;
  }

  .chapter2Text2M {
    max-height: 99px;
  }

  .chapter3TextM {
    max-height: 124px;
  }

  .chapter3Text2M {
    max-height: 124px;
  }

  .chapter1TextUSM {
    max-height: 196px;
  }

  .chapter1TextUS2M {
    max-height: 174px;
  }

  .chapter2TextUSM {
    max-height: 124px;
  }

  .chapter2TextUS2M {
    max-height: 124px;
  }

  .chapter3TextUSM {
    max-height: 150px;
  }

  .chapter3TextUS2M {
    max-height: 225px;
  }
}

@media screen and (max-width: 400px) {
  .chapter {
    &-area {
      &-icon {
        width: 50%;
      }
    }
  }

  .left {
    width: 1.2rem;
  }

  .right {
    width: 1.2rem;
  }
}

.prologBack {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  display: flex;
  align-items: center;
  color: $Fgray;
  cursor: pointer;

  img {
    width: 1rem;
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    margin-right: 0.4rem;
    opacity: 0.8;
  }
}
