@import "util/Color.scss";

.InfoDownloadArea {
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reservation {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s;
  transition: background-image 1s;
  overflow: hidden;

  &-cover {
    width: 100%;
    height: 100%;
    background-color: $Gblack-Opacity2;
    display: flex;
    justify-content: center;
    position: relative;

    &-sns {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &-cover {
        margin-top: 1rem;
        width: 3.8rem;
        height: 2.2rem;
        color: $Fwhite;
        display: flex;
        align-items: center;
        border-radius: 10px 0 0 10px;
        font-size: 0.8rem;
        transition: all 0.3s;

        &-img {
          width: 1.8rem;
          margin: 0 1rem;
          opacity: 0.6;
        }

        &:hover {
          width: 12rem;
          background-color: $Gskyblue;
        }

        &:hover .font {
          left: 60px;
        }
      }

      &-coverM {
        margin-top: 1rem;
        width: 3.8rem;
        height: 2.2rem;
        color: $Fwhite;
        display: flex;
        align-items: center;
        border-radius: 10px 0 0 10px;
        font-size: 0.8rem;
        transition: all 0.3s;

        &-img {
          width: 1.8rem;
          margin: 0 1rem;
          opacity: 0.6;
        }
      }
    }

    &-area {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      &-logo {
        margin-top: 12vh;
        width: 18rem;
      }

      &-reser {
        color: $Fgray;
        margin-top: 4rem;
      }

      &-reserUS {
        max-width: 30%;
        color: $Fgray;
        margin-top: 4rem;
      }

      &-text {
        margin-top: 4rem;
        margin-bottom: 2rem;
        max-width: 20%;
      }

      &-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;

        &-nomal {
          width: 15rem;
        }

        &-mini {
          width: 20rem;
          margin-top: 1rem;
        }
      }
    }
  }
}

.logocafe {
  margin-top: 2rem;
  margin-right: 1rem;
}

.reservationBackImg1 {
  background-image: url("../../assets/img/Illust10.jpg");
}

.reservationBackImg2 {
  background-image: url("../../assets/img/Illust7-setting.jpg");
}

@media screen and (max-width: 1000px) {
  .reservationBackImg2 {
    background-position-x: 10%;
  }

  .reservationBackImg1 {
    background-position-x: 10%;
  }
}

@media screen and (max-width: 1000px) {
  .reservation {
    background-position: center;
    background-size: cover;
    transition: background-image 1s;
    min-height: 400px;

    &-cover {
      &-area {
        &-logo {
          width: 18rem;
          margin-top: 2rem;
        }

        &-reser {
          display: none;
        }

        &-text {
          width: 70%;
          margin-top: 13rem;
          margin-bottom: 3rem;
        }

        &-footer {
          &-nomal {
            width: 15rem;
          }

          &-mini {
            width: 25rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .reservationBackImg1 {
    background-image: url("../../assets/img/Illust10.jpg");
    background-position-x: 70%;
  }

  .reservationBackImg2 {
    background-image: url("../../assets/img/Illust7-setting.jpg");
    background-size: cover;
  }

  .InfoDownloadArea {
    &-img {
      width: 65%;
    }
  }

  .reservation {
    background-position: center;
    transition: background-image 1s;

    &-cover {
      &-area {
        &-logo {
          width: 10rem;
          margin-top: 2rem;
        }

        &-reser {
          display: none;
        }

        &-text {
          width: 80%;
          margin-top: 6rem;
        }

        &-footer {
          &-nomal {
            width: 15rem;
          }

          &-mini {
            width: 25rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .reservationBackImg1 {
    background-image: url("../../assets/img/Illust10.jpg");
    background-position-x: 70%;
  }

  .reservationBackImg2 {
    background-image: url("../../assets/img/Illust7-setting.jpg");
    background-size: cover;
  }

  .InfoDownloadArea {
    &-img {
      width: 50%;
    }

    .reservation {
      background-position: center;
      transition: background-image 1s;

      &-cover {
        &-area {
          &-logo {
            width: 10rem;
            margin-top: 2rem;
          }

          &-reser {
            display: none;
          }

          &-text {
            width: 80%;
            margin-top: 10rem;
          }

          &-footer {
            &-nomal {
              width: 10rem;
            }

            &-mini {
              width: 20rem;
            }
          }
        }
      }
    }
  }
}
