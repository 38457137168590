@import "util/Color.scss";

.world {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s;
  position: relative;

  &-area {
    width: 100%;
    height: 100%;
    background-color: $Gblack-Opacity;
    display: flex;
    justify-content: center;
    z-index: 10;
    position: absolute;
    overflow: hidden;

    &-text {
      width: 70%;
      max-width: 1400px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title {
        margin-top: 8rem;
      }

      &-titleU {
        margin-top: 8rem;
      }

      &-nav {
        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 3rem;

        &-imgnav {
          height: 29px;
          object-fit: contain;
          opacity: 0.6;
          transition: all 0.3s;
          padding: 5px 10px;
        }
      }

      &-info {
        margin-top: 8rem;
      }
    }
  }
}

.WorldVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
  z-index: 2;
  opacity: 0.3;
}

.Fantasy {
  background-image: url("../../assets/img/Illust9.jpg");
}

.Dream {
  background-image: url("../../assets/img/Illust5.jpg");
}

.Aurora {
  background-image: url("../../assets/img/Illust8.jpg");
}

.Merhen {
  background-image: url("../../assets/img/Illust6.jpg");
}

.Dominion {
  background-image: url("../../assets/img/Illust4.jpg");
}

.opacity {
  opacity: 1;
}

.mobileWorldMenu {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  &-prev {
    position: absolute;
    transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    left: 3%;
    top: calc(50% - 1rem);
    width: 2rem;
    z-index: 98;
  }

  &-next {
    position: absolute;
    right: 3%;
    z-index: 98;
    top: calc(50% - 1rem);
    width: 2rem;
  }

  &-card {
    height: 21px;
    object-fit: cover;
  }

  &-cardU {
    height: 19px;
    object-fit: cover;
  }

  &-area {
    display: flex;
    width: 4rem;
    height: 1rem;
    justify-content: space-around;
    align-items: center;
    margin-top: 0.4rem;

    &-list {
      width: 0.2rem;
      height: 0.2rem;
      background-color: $Fgray;
      border-radius: 50%;
    }

    &-listOn {
      width: 0.4rem;
      height: 0.4rem;
      background-color: $GYellow;
      border-radius: 50%;
    }
  }
}

@media screen and (max-height: 800px) {
  .world {
    &-area {
      &-text {
        &-title {
          margin-top: 5rem;
        }

        &-titleU {
          margin-top: 5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .world {
    min-height: 400px;
    &-area {
      &-text {
        &-info {
          margin-top: 4rem;
        }

        &-nav {
          width: 90%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin-top: 3rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .world {
    min-height: 400px;
    &-area {
      &-text {
        width: 90%;

        &-title {
          margin-top: 4rem;
        }

        &-titleU {
          margin-top: 4rem;
        }

        &-nav {
          width: 90%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin-top: 3rem;
        }

        &-info {
          width: 100%;
          margin-top: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .world {
    &-area {
      &-text {
        width: 90%;

        &-title {
          margin-top: 4rem;
        }

        &-titleU {
          margin-top: 4rem;
        }

        &-nav {
          width: 70%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin-top: 3rem;
        }
        &-info {
          width: 100%;
          margin-top: 4rem;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .world {
    &-area {
      &-text {
        width: 100%;

        &-title {
          margin-top: 5rem;
        }

        &-titleU {
          margin-top: 5rem;
        }

        &-nav {
          width: 90%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin-top: 3rem;

          &-imgnav {
            height: 0.9rem;
          }
        }

        &-info {
          margin-top: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .mobileWorldMenu {
    &-prev {
      width: 1.2rem;
    }

    &-next {
      width: 1.2rem;
    }
  }

  .world {
    &-area {
      &-text {
        width: 100%;

        &-title {
          width: 8rem;
          margin-top: 2rem;
        }

        &-titleU {
          width: 12rem;
          margin-top: 2rem;
        }

        &-nav {
          width: 85%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin-top: 2rem;

          &-imgnav {
            height: 0.8rem;
          }
        }
      }
    }
  }
}
