@import "util/Color.scss";

.MobileMenuIcon {
  width: 2rem;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 98;
}

.MobileMenu {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $Gblack-Mobile;
  transition: all 0.7s;

  &-area {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-logo {
      width: 50%;
      margin-top: 4rem;
    }

    &-menu {
      width: 70%;
      height: calc(var(--vh, 1vh) * 40);
      margin-top: 1rem;

      &-line {
        display: flex;
        align-items: center;
        position: relative;
        height: 6px;

        &-die {
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-bottom-color: $BGray;
          position: absolute;
          top: -5px;
        }

        &-die::after {
          content: "";
          position: absolute;
          left: -5px;
          top: 5px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-top-color: $BGray;
        }

        &-dier {
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-bottom-color: $BGray;
          position: absolute;
          top: -5px;
          right: 0;
        }

        &-dier::after {
          content: "";
          position: absolute;
          right: -5px;
          top: 5px;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-top-color: $BGray;
        }

        &-solid {
          position: relative;
          width: 100%;
          height: 1px;
          background-color: $BGray;
          top: 2px;
          margin: 0 1px;
        }
      }

      &-card {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(var(--vh, 1vh) * 7);

        img {
          height: 15px;
          object-fit: contain;
        }
      }

      &-cardUSM {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(var(--vh, 1vh) * 8);

        img {
          height: 13px;
          object-fit: contain;
        }
      }

      &-cardUSh {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(var(--vh, 1vh) * 8);

        img {
          height: 12px;
          object-fit: contain;
        }
      }
    }

    &-iconList {
      width: 80%;
      display: flex;
      justify-content: space-around;
      margin-top: 1rem;

      &-icon {
        height: 2rem;
        object-fit: cover;
      }

      &-cafe {
        height: 2.2rem;
        object-fit: cover;
      }
    }

    &-license {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $BGray;
      font-size: 0.8rem;
    }

    &-copy {
      width: 90%;
      height: 22px;
      object-fit: contain;
      margin-bottom: 3rem;
    }
  }
}

.mobilHide {
  top: calc(var(--vh, 1vh) * -100);
}

.mobilShow {
  top: 0;
}
